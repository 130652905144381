<template>
  <div>
    <Actions :show="show" :view="view"  :gridApi="gridApi" :loadData="loadData"  :setShow="setState" :modal="{interface: I_SIRHELYEK, data, title: 'Sirhely'}"/>
    <div class="d-flex">
      <div class="col-lg-7 d-flex pl-4 mt-4">
        <input v-model="model.sirhelytabla" placeholder="Sirhelytabla" class="form-control mr-3">
        <input v-model="model.sorszam" placeholder="Sorszam" class="form-control mr-3">
        <b-button  variant="success" @click="filterData" class="mr-3">
          Keresés
        </b-button>
        <b-button variant="success" @click="reset">
          Visszallitas
        </b-button>
      </div>
    </div>
    <ag-grid-vue
      style="width: 100%; height: 55vh;"
      class="ag-theme-alpine mt-4"
      :columnDefs="columnDefs"
      :modules="modules"
      :defaultColDef="defaultColDef"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :localeText="AG_GRID_LOCALE_HU"
      :rowData="filteredData"
      rowSelection="multiple">
    </ag-grid-vue>
  </div>
</template>

<script>
import {Grid} from "../mixins/Grid";
import Actions from "../components/Grid/Actions";
import {I_SIRHELYEK} from "../constants/interfaces";

export default {
  name: "Sirhelyeks",
  components: {Actions},
  mixins: [Grid],
  data(){
    return{
      I_SIRHELYEK: I_SIRHELYEK(this),
      model: {
        sirhelytabla: '',
        sorszam: ''
      }
    }
  },
  async beforeMount() {
    this.columnDefs = [...this.columnDefs,...I_SIRHELYEK(this).fields].filter(e=> e.hideGrid !== true);
    await this.loadData();
    this.data = {jogosultak: [],elhunytak: [], orokosok: []}
  },
  methods: {
    async filterData(){
      const first = `sirhelytabla=${this.model.sirhelytabla}`
      const second = `sorszam=${this.model.sorszam}`
      const query = '/sirhelies?' + (this.model.sirhelytabla ? this.model.sorszam ? first + '&' + second : first : this.model.sorszam ? this.model.sirhelytabla ? second + '&' + first : second : '')
      const {data} = await this.axios.get(query + '&_limit=-1')
      this.filteredData = data.filter(e=> e.hideGrid !== true);
    },
    reset(){
      this.filteredData = this.rowData.filter(e=> e.hideGrid !== true)
      this.model = {
        sirhelytabla: '',
        sorszam: ''
      }
    },
    async loadData(instance = null,load = ''){
      switch (load){
        case "add":{
          window.sirhely.push(instance?.data)
          break
        }
        case "edit":{
          window.sirhely = window.sirhely.map(e => {
            if(e.id === instance.data?.id){
              e = instance.data
            }
            return e
          })
          break
        }
        case "delete":{
          window.sirhely = window.sirhely.filter(e=>e.id !== instance)
          break
        }
      }
      this.rowData =  window.sirhely;
      this.rowData = this.rowData.map(e=>{
        return e
      })
      this.filteredData = this.rowData
      this.$toasted.show('Adatok frissitve', {
        duration: 2000,
      })
    }
  }
}
</script>

<style scoped>

</style>
